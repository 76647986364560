<template>
    <!-- 管理员审核需求 -->
    <div class="page">
       
        <div class="demand-content">
            <van-tabs v-model="active" @change="changeTab">
                <van-tab title="待审核">
                    <div class="content-top" v-for="(item,index) in demands" :key="index" >
                        <div class="main-top"  @click="goDemandDetails(index)">
                            <div class="worker-photo">
                                <img :src="item.createdUserAvatar" alt="">
                            </div>
                            <div class="con-right">
                                <div class="con-top">
                                    <div class="con-title">
                                        {{item.title}}
                                    </div>
                                    <div class="progress">
                                        {{item.createdDate}}
                                    </div>
                                </div>
                                <div class="address">
                                    <div>
                                      {{ item.contactsName }}:{{ item.contactsTel }}
                                    </div>
                                </div>
                                <div class="con-bottom">
                                    <div class="con-money">
                                        {{item.salary}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="requirement">
                            要求:{{item.content}}
                        </div>
                        <div class="place">
                            <span>
                                工程地点:{{item.workingPlaceName}}
                            </span>
                            <span style="float: right">{{item.date}}</span>
                        </div>
                      <div class="sh">
                        <van-button class="sh-btn" size="mini" plain type="info" @click="onSuccess(index)">审核通过</van-button>
                        <van-button class="sh-btn" size="mini" plain type="danger" @click="onShowFail(index)">拒绝</van-button>
                      </div>
                    </div>
                </van-tab>
                <van-tab title="未通过">
                  <div class="content-top" v-for="(item,index) in demands" :key="index" @click="goDemandDetails(index)">
                    <div class="main-top" >
                      <div class="worker-photo">
                        <img :src="item.createdUserAvatar" alt="">
                      </div>
                      <div class="con-right">
                        <div class="con-top">
                          <div class="con-title">
                            {{item.title}}
                          </div>
                          <div class="progress">
                            {{item.createdDate}}
                          </div>
                        </div>
                        <div class="address">
                          <div>
                            {{ item.contactsName }}:{{ item.contactsTel }}
                          </div>
                        </div>
                        <div class="con-bottom">
                          <div class="con-money">
                            {{item.salary}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="requirement">
                      要求:{{item.content}}
                    </div>
                    <div class="place">
                            <span>
                                工程地点:{{item.workingPlaceName}}
                            </span>
                      <span style="float: right">{{item.date}}</span>
                    </div>
                    <div class="sh">
                      <van-button class="sh-btn" size="mini" plain type="info" @click="onSuccess(index)">审核通过</van-button>
<!--                      <van-button class="sh-btn" size="mini" plain type="danger" @click="onShowFail(index)">拒绝</van-button>-->
                    </div>
                  </div>
                </van-tab>
            </van-tabs>
        </div>


      <van-dialog v-model="showFail" title="拒绝原因" show-cancel-button @confirm="onFail" @cancel="showFail=false">
        <van-field v-model="dto.failRemark" placeholder="请输入拒绝原因" />
      </van-dialog>

    </div>
</template>


<script>
import * as demandApi from "../../api/demand/index";
import { Dialog,Toast,Notify } from 'vant';
import {adminFail, adminGetDate, adminOK} from "../../api/demand/index";
    export default {
        components: {},
        data() {
            return {
              showFail:false,
              active: 0,
              place: undefined,
              showPicker: false,
              demands: [],
              columns: ["济南", "高新"],
              params:{
                status:"REVIEW"
              },
              dto:{
                id: undefined,
                failRemark: ""
              }
            };
        },
        mounted() {
            this.loadData();
        },
        methods: {
          changeTab(e){
            console.info("changeTab",e)
            this.params.status=e==0?"REVIEW":"FAIL"
            this.loadData();
          },
          async loadData() {
            let r = await demandApi.adminGetDate(this.params);
            console.info("loadData",r)
            this.demands = r.content;
            this.isDemandLast = r.last;
          },
          go() {

          },
          onConfirm(value) {
              this.place = value;
              this.showPicker = false;
          },
          onCancel() {
              this.place = undefined;
              this.showPicker = false;
          },
          goDemandDetails(index){
            let d  = this.demands[index]
            console.info("=====",d)
              this.$router.push(`admin-demand-details/`+d.id)
          },
          async onSuccess(e){
            this.dto.id=this.demands[e].id
            console.info("onSuccess",e,this.dto)
            let r = await demandApi.adminOK(this.dto);
            console.info("onSuccess",e,this.dto)
            this.demands.splice(e,1);
            Notify({ type: 'primary', message: '审核通过' });
          },
          onShowFail(e){
            this.dto.id=this.demands[e].id
            this.showFail=true
          },
          async onFail(e){
            let r = await demandApi.adminFail(this.dto);
            this.demands.splice(e,1);
            Notify({ type: 'danger', message: '拒绝成功' });
          }
        },
    };
</script>

<style lang="less" scoped>
    .demand-content {
        .content-top {

            min-height: 160px;
            background: #FFFFFF;

            .main-top {
                border-bottom: 1px solid #F7F7F7;
                clear: both;
                display: flex;
                justify-content: space-between;
                padding: 8px 8px;
                margin-top: 8px;

                .worker-photo {
                    height: 60px;
                    width: 60px;
                    text-align: center;
                    border-radius: 5px;
                    img {
                        height: 60px;
                        width: 60px;
                    }
                }

                .con-right {
                    height: 60px;
                    width: 80%;
                    padding: 5px;

                    .con-top {
                        display: flex;
                        justify-content: space-between;

                        .con-title {
                            height: 20px;
                            width: 50%;
                            font-size: 12px;
                            color: #212121;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .progress {
                            font-size: 10px;
                            color: #3B83CE;
                        }

                    }

                    .address {
                      min-height: 20px;
                      font-size: 10px;
                      color: #8B8D90;
                      display: flex;
                      justify-content: space-between;

                    }

                    .con-bottom {
                      min-height: 30px;
                        display: flex;
                        justify-content: space-between;

                        .con-money {
                            color: #E7807D;
                        }
                    }
                }

            }
        }

        .requirement {
            padding: 6px;
            font-size: xx-small;
            color: #212121;
            border-bottom: 1px solid #F7F7F7;
            min-height: 20px;
            //overflow: hidden;
            //text-overflow: ellipsis;
            //display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .place {
            padding: 6px;
            font-size: xx-small;
            color: #C3C3C3;
            min-height: 15px;
            border-bottom: 1px solid #F7F7F7;
        }

    }
.sh{
  padding:  10px;
  text-align: right;
  .sh-btn{
    margin-left: 10px;
  }
}

</style>
